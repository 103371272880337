@import './_variables.scss';
@import './_mixins.scss';

.console-container {
    display: flex;
    flex-direction: column;
    height: 100vh; // Fallback
    height: calc(var(--vh, 1vh) * 100);
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 20px 20px 18px 20px;

    .console-title {
        display: inline-flex;
        align-items: center;
        max-width: calc(100vw - 50px);
        margin-bottom: 8px;

        button {
            margin-top: 0;
            margin-right: 8px;
        }

        h2 {
            margin-bottom: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .console-help-icon {
            flex-shrink: 0;
            width: 35px;
            height: 35px;
            margin-left: auto;
            margin-right: 0;
            padding: 0;
            border-radius: 35px;
        }
    }

    .console-messages {
        flex: 1;
        padding: 20px;
        margin-bottom: 20px;
        overflow: hidden;
        background-color: #333;
        box-shadow: 0 0 40px rgba(0, 0, 0, 0.5);
        font-family: $font-family-monospace;
        font-size: 12px;
        direction: ltr;
        text-align: left;
        word-spacing: normal;
        word-break: normal;
        line-height: 1.6;
        font-weight: 400;
        border: 1px solid $outline-primary;
        border-radius: 5px;
        user-select: text;

        .list-item-text {
            font-family: $font-family-monospace;
            font-size: 12px;
            direction: ltr;
            text-align: left;
            word-spacing: normal;
            word-break: normal;
            line-height: 1.6;
            font-weight: 400;
            margin: 0;
            padding: 0;

            pre {
                color: inherit;
            }

            img {
                margin: 5px 0;
            }
        }
    }

    .console-commands {
        display: inline-flex;
        max-width: 900px;

        &.is-drawer {
            padding-right: 85px;
        }

        .console-input {
            flex: 1;
        }
    }
}
