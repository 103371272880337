@import './_variables.scss';
@import './_mixins.scss';

.creatives-container {
    min-height: calc(100vh - 71px); // Fallback
    min-height: calc(var(--vh, 1vh) * 100 - 71px);
    padding-bottom: 60px; // Allow space for help widget and offline banner
    background-color: $page-dark;
    padding-left: 20px;
    padding-right: 20px;

    main {
        min-height: 100%;
        margin: 0 auto;
        // max-width: 800px;
        max-width: 1200px;

        .report-form {
            justify-content: center;
            background-color: $header-dark;
            padding: 20px;
            border-bottom: 1px solid $outline-grey;

            .custom-form-group,
            .custom-input-group {
                padding: 10px;
                border-radius: 7px;
                background-color: $page-dark;
            }
        }

        .user-images {
            margin-top: 20px;
            display: grid;
            grid-template-rows: auto;
            grid-template-columns: repeat(4, 1fr);
            gap: 20px;
        }

        .clear-all-button {
            display: flex;
            justify-content: center;
        }

        .proof-card {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            border-radius: 5px;
            background-color: $page-grey;
            padding: 10px;

            img {
                max-width: 100%;
                max-height: 100px;
            }

            h3 {
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .image-title {
                display: grid;
            }

            .remove-button {
                position: absolute;
                top: -10px;
                right: -10px;
                border-radius: 100%;
            }
        }

        .results-header {
            background-color: $header-dark;
            border-bottom: 1px solid $outline-grey;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px 30px;
            z-index: 2;

            @include media-breakpoint-up(md) {
                position: sticky;
                top: 0px;
            }

            .results-left {
                display: flex;
                align-items: center;
            }
        }

        .results-summary {
            display: flex;
            align-items: center;
        }

        .nav-content-container {
            width: 100%;
        }

        .results-footer {
            display: flex;
            justify-content: center;
        }

        .report-form {
            display: flex;
            align-items: center;
        }
    }
}

.summary-export {
    padding: 20px;

    .create-button {
        display: flex;
        justify-content: flex-end;
    }
}
