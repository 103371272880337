@import './_variables.scss';
@import './_mixins.scss';

.org-select {
    height: 390px;
    padding: 20px;

    .custom-select-wrapper {
        padding-right: 0;
    }
}

.diagnostics-global-container {
    padding: 10px 10px 60px 10px;

    .selected-organisation {
        display: inline-flex;
        align-items: center;
        margin-bottom: 10px;
        color: $text-title;

        h1 {
            margin-bottom: 0;
        }

        .action-wrapper {
            margin-left: 10px;
        }
    }
}

.diagnostics-global-digest {
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint-up(lg) {
        max-width: 992px;
    }

    @include media-breakpoint-down(lg) {
        max-width: 100%;
    }

    .form-inline {
        justify-content: center;
    }

    .digest-title {
        margin-top: 20px;
        margin-bottom: 10px;
        text-align: center;
    }

    .collapse-expand-all {
        width: 100%;
        display: inline-flex;
        justify-content: flex-end;

        & > :first-child {
            padding-right: 5px;
            border-right: 1px solid $outline-grey;
        }

        & > :last-child {
            padding-left: 5px;
        }
    }

    .collapsible-title {
        margin-bottom: 0;

        .digest-collapsible-header {
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                margin-bottom: 0;
            }

            display: inline-flex;
        }

        .bp3-tag {
            margin-left: 10px;
            background-color: $badge-background;
            color: $text-badge;
        }

        .bp3-icon-chevron-up {
            margin-left: auto;
        }
    }

    .bp3-collapse-body {
        margin-top: 10px;
    }

    .error-section {
        margin-bottom: 10px;

        & > .collapsible-title {
            padding: 10px;
            margin-bottom: 0;
        }

        & > .bp3-collapse > .bp3-collapse-body {
            margin: 10px;
        }

        .digest-table-container {
            margin: 0;

            .collapsible-title {
                padding: 10px;
            }

            .bp3-collapse-body {
                margin-top: 0;
                border-top: 1px solid $outline-black;
            }
        }
    }

    .digest-table-container {
        width: 100%;
        margin: 10px 0;
        padding: 10px;
        background-color: $table-group-background;
        border-radius: 7px;

        .bp3-collapse-body {
            overflow-x: auto;
        }
    }

    .digest-table {
        width: 100%;
        color: #ffffff;

        thead tr {
            background-color: $table-subheader-background;
            padding: 10px;
        }

        th {
            padding: 0 5px;
            text-align: left;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            text-transform: uppercase;
        }

        tr {
            width: 100%;
            height: 30px;

            &:not(:last-of-type) {
                border-bottom: 1px solid $outline-grey;
            }
        }

        td {
            padding: 0 5px;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            word-break: break-word;
        }

        .time-column {
            width: 130px;
        }

        .duration-column {
            width: 85px;
        }

        .count-column {
            width: 60px;
            text-align: right;
        }

        .ongoing-row {
            background-color: $table-error-dark;
        }
    }
}
