@import './_variables.scss';
@import './_mixins.scss';

html,
body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: $page-dark;
    font-family: $font-family-base;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $text-primary;

    .bp3-overlay {
        user-select: none;

        &.bp3-overlay-scroll-container {
            position: fixed !important;
        }
    }
}

.application,
.popout {
    display: block;
    position: relative;
    height: 100%;
    width: 100%;
    min-width: 320px;
    min-height: 100vh; // Fallback
    min-height: calc(var(--vh, 1vh) * 100);
    // Prevents selecting elements. If selecting elements is desired (i.e. table content),
    // user-select: text; must be enabled for that component
    // NOTE: Input components are an exception, the text within an input component should
    // always be selectable
    user-select: none;
}

.application {
    display: flex;
    flex-direction: column;
}

.application-content {
    display: block;
    position: relative;
}

h1 {
    font-family: $font-family-light;
    font-size: 22px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $text-title;
}

h2 {
    font-family: $font-family-light;
    font-size: 20px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $text-title;
}

h3 {
    font-family: $font-family-semibold;
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $text-title;
}

h4 {
    font-family: $font-family-light;
    font-size: 15px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $text-title;
}

h5 {
    font-family: $font-family-base;
    font-size: 15px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $text-title;
}

h6 {
    font-family: $font-family-base;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $text-title;
}

a {
    color: inherit !important;

    &:hover {
        text-decoration: none !important;
    }
}

// Prevent highlighting and ghost dragging of images
img {
    user-select: none;
    // user-drag: none;

    &:focus {
        outline: none;
    }
}

code {
    font-family: $font-family-monospace;
}

hr {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
    border-color: $outline-grey;
}

.text-cyan {
    color: $custom-cyan;
}

.text-orange {
    color: $custom-orange;
}

.text-yellow {
    color: $custom-yellow;
}

.text-blue {
    color: $custom-blue;
}

.text-purple {
    color: $custom-purple;
}

.text-black {
    color: $text-black;
}

.nav-item {
    &:hover {
        cursor: pointer;
    }
}

.loading-state {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.selectable-text {
    user-select: text;
}

.horizontal-scroll-shadows {
    background-image:
        /* Shadows */ linear-gradient(to right, $page-dark, $page-dark),
        linear-gradient(to right, $page-dark, $page-dark),
        /* Shadow covers */ linear-gradient(to right, rgba(200, 200, 200, 0.5), rgba(0, 0, 0, 0)),
        linear-gradient(to left, rgba(200, 200, 200, 0.5), rgba(0, 0, 0, 0));
    background-position: left center, right center, left center, right center;
    background-repeat: no-repeat;
    background-color: $page-dark;
    background-size: 20px 100%, 20px 100%, 10px 100%, 10px 100%;
    // NOTE: iOS is lacking background-attachment support https://caniuse.com/#feat=background-attachment
    // Could be replaced with a JS solution such as https://github.com/appleple/scroll-hint
    background-attachment: local, local, scroll, scroll;
}

.hide-scrollbars {
    scrollbar-width: none; // Firefox

    &::-webkit-scrollbar {
        display: none; // Safari and Chrome
    }
}

.custom-scrollbars {
    // Disabled on mobile devices as scrollbars are managed internally and render as a light colour above dark backgrounds
    // NOTE: These properties are ignored on Firefox
    @include media-breakpoint-up(sm) {
        ::-webkit-scrollbar {
            width: 6px;
            height: 6px;
            cursor: pointer;
        }

        ::-webkit-scrollbar-track {
            background: transparent;
        }

        ::-webkit-scrollbar-thumb {
            background: rgba(255, 255, 255, 0.5);
            border-radius: 4px;
        }

        ::-webkit-scrollbar-corner {
            background: transparent;
        }
    }
}

.pre-line {
    white-space: pre-line;
}

.privacy {
    min-height: 100vh;
    width: 100%;
    background-color: #ffffff;

    iframe {
        max-width: 838px;
        height: 100%;
        margin: 0 auto;
    }
}

.text-underline {
    text-decoration: underline;
}

.text-clickable {
    cursor: pointer;
}

.border {
    border: 1px solid $outline-grey;
}

.checkered-background {
    // Show checkered pattern behind transparent images
    background-color: #ffffff;
    background-image: linear-gradient(45deg, #efefef 25%, transparent 25%, transparent 75%, #efefef 75%, #efefef),
        linear-gradient(45deg, #efefef 25%, transparent 25%, transparent 75%, #efefef 75%, #efefef);
    background-position: 0 0, 10px 10px;
    background-size: 21px 21px;
}

.align-middle {
    display: inline-flex;
    align-items: center;
}

.h-full {
    height: 100%;
}

.w-full {
    width: 100%;
}

.uppercase {
    text-transform: uppercase;
}

.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
