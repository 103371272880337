@import './_variables.scss';
@import './_mixins.scss';

.screen-filters {
    position: fixed;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 285px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: $page-dark;
    border-bottom: 1px solid $outline-white;

    @include media-breakpoint-up(sm) {
        top: 140px;
        width: 397px;
    }

    @include media-breakpoint-down(sm) {
        top: 70px;
        width: 100%;
    }

    .screen-filters-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;

        .custom-form-group {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            flex-grow: 1;
            height: 55px;
            max-height: 55px;
            margin-bottom: 10px;
            padding: 10px;
        }
    }
}

.screen-diagnostics-container {
    display: flex;
    flex-direction: row;
    background-color: $page-dark;
    height: 100%;
    width: 100%;

    .diagnostics-screen-sidebar {
        display: flex;
        flex-direction: column;
        // min-height: calc(100vh - 72px); // Fallback
        // min-height: calc(var(--vh, 1vh) * 100 - 72px);
        height: 100%;
        flex-shrink: 0;
        background-color: $page-dark;
        border-right: 3px solid $outline-grey;
        z-index: 1;

        @include media-breakpoint-up(sm) {
            width: 400px;
        }

        @include media-breakpoint-down(sm) {
            width: 100vw;
            transition: transform 0.6s cubic-bezier(0, 0.52, 0, 1);

            &.show-mobile {
                margin-right: -100vw;
                transform: translateX(0);
            }

            &.hide-mobile {
                transform: translateX(-100vw);
            }
        }
    }
}

.diagnostics-overview {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    margin: 10px 20px;
    background-color: $page-dark;

    .diagnostics-overview-title {
        display: flex;
        align-items: center;
        margin-bottom: 0;
    }

    .diagnostics-filters-toggle {
        z-index: 1010;
    }

    .custom-select-wrapper {
        padding-right: 0;
    }
}

.diagnostics-overview-filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    margin: 10px 20px;
    background-color: $page-dark;

    .custom-search {
        width: 100%;
    }

    .diagnostics-search {
        height: 40px;
    }

    .custom-select-wrapper {
        padding-right: 0;
    }
}

.screen-main-view {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    min-height: calc(100vh - 72px); // Fallback
    min-height: calc(var(--vh, 1vh) * 100 - 72px);

    @include media-breakpoint-up(sm) {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }

    @include media-breakpoint-down(sm) {
        width: 100vw;
        padding-left: 10px;
        padding-right: 10px;
        transition: transform 0.6s cubic-bezier(0, 0.52, 0, 1);

        &.show-mobile {
            margin-left: -100vw;
        }

        &.hide-mobile {
            margin-left: 0;
        }
    }

    .tab-content,
    .tab-content > .active {
        display: flex;
        flex-direction: column;
        height: 100%;
        flex-grow: 1;
    }
}

.diagnostics-selected-filter {
    margin: 0 20px 20px 20px;

    .row {
        flex-wrap: nowrap;
        margin-top: 10px;

        span {
            font-size: 15px;
            color: $text-title;
        }

        .bp3-tag {
            background-color: $button-primary;

            // margin-left: 30px
            font-size: 12px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            .bp3-tag-remove {
                margin-left: 3px;
                border-left: 1px solid $header-dark;
            }
        }
    }
}

.diagnostics-screens-table {
    height: 100%;

    .bvt-table {
        .ReactVirtualized__Table__headerRow {
            padding-left: 20px !important;
            padding-right: 20px !important;
        }

        .ReactVirtualized__Table__Grid {
            padding-bottom: 30px; // Allow space for offline banner
            user-select: none;
        }

        .ReactVirtualized__Table__row {
            padding-left: 20px !important;
            padding-right: 0 !important;

            &:hover {
                background-color: $table-hover;
                cursor: pointer;
            }

            .status-col {
                overflow: visible !important;
            }

            .screen-info-cell {
                margin-right: 0 !important;

                .diagnostics-overview-screen-heading {
                    height: 40px;

                    h6 {
                        margin-bottom: 0;
                    }

                    .screen-subheading {
                        display: inline-flex;
                        align-items: center;
                        margin-top: 5px;

                        h6 {
                            padding-top: 3px;
                        }

                        .screen-subheading-icon {
                            display: flex;
                            justify-content: center;
                            width: 14px;
                            margin-right: 6px;

                            img {
                                height: 14px;
                            }
                        }
                    }
                }
            }

            .ReactVirtualized__Table__rowColumn {
                padding-top: 10px !important;
                padding-bottom: 10px !important;
            }
        }
    }
}

.status-cell {
    display: flex;
}

.screen-name {
    display: inline-flex;
    align-items: center;
    width: 100%;

    h2 {
        margin-bottom: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.screen-details {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    color: $text-data;
    font-size: 15px;

    .details {
        display: inline-flex;
        max-width: 100%;
        margin-top: 10px;
        margin-right: 20px;

        h4,
        h5 {
            margin-bottom: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        h4 {
            margin-right: 8px;
        }

        .details-icon {
            height: 15px;
            margin-right: 8px;
        }
    }

    .tooltip-details-target {
        align-items: center;
        cursor: pointer;
    }
}

.screen-errors {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-grow: 1;

    .diagnostics-errors-content {
        display: flex;
        flex-grow: 1;
        overflow: hidden;

        .diagnostics-error-group-container {
            display: flex;
            flex-direction: column;
            width: 100%;
            flex-shrink: 0;

            // Error group list
            .error-group-list {
                height: 100%;

                [class*='ReactVirtualized__'] {
                    &:focus {
                        outline: none !important;
                    }
                }

                .bvl-list {
                    padding-bottom: 30px; // Allow space for offline banner
                }
            }
        }
    }
}

.error-group-list-row-wrapper {
    padding-bottom: 10px;

    .error-group-list-row {
        height: 100%;
        background-color: $table-group-background;
        border-radius: 7px;

        .collapsable {
            cursor: pointer;
        }

        .error-group-list-group {
            display: inline-flex;
            align-items: center;
            width: 100%;
            max-width: 100%;
            padding: 10px;
            overflow-x: auto;

            .group-name-assign-cell {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                margin-right: 10px;

                @include media-breakpoint-down(sm) {
                    button span {
                        border-bottom: none;
                    }
                }

                h6 {
                    margin-bottom: 0;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
            }
        }

        .custom-table-expander {
            display: flex;
            justify-content: center;
            margin: 0 10px;

            img {
                height: 9px;
            }
        }

        // Error table
        .error-table {
            width: 100%;
            height: calc(100% - 50px);
            padding-left: 10px;
            padding-right: 10px;
            font-size: 14px;

            .bvt-table {
                .ReactVirtualized__Table__headerRow {
                    padding-right: 0 !important;
                    background-color: $table-subheader-background;
                }

                .ReactVirtualized__Table__Grid {
                    .ReactVirtualized__Table__row {
                        padding-right: 0 !important;
                        cursor: pointer;

                        &:last-child {
                            border-bottom: none;
                        }
                    }

                    .assigned-cell-wrapper {
                        height: 32px;
                        padding: 0 !important;

                        .assigned-cell {
                            height: 100%;
                            padding: 7px 10px;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
}

.screen-error-history {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-grow: 1;

    .history-controls-collapse {
        z-index: 1;
    }

    .selected-search-params-tags {
        margin-bottom: 10px;

        .bp3-tag {
            background-color: $button-primary;
            margin-right: 10px;
        }
    }

    .scope-button {
        @include media-breakpoint-up(sm) {
            margin-bottom: 10px;
        }
    }

    .error-log-wrapper {
        display: flex;
        height: 100%;
        flex: 1 100%;
        width: 100%;

        .error-log-table {
            height: unset;

            .bvm-table {
                padding-bottom: 30px; // Allow space for offline banner
            }
        }
    }
}

// Tile View
.tile-view-controls-wrapper {
    position: absolute;
    left: 0;
    z-index: 1000;
    width: 100%;

    @include media-breakpoint-down(md) {
        top: 10px;
    }

    @include media-breakpoint-up(md) {
        top: 20px;
    }

    .tile-view-controls {
        justify-content: flex-end;

        @include media-breakpoint-down(md) {
            margin: 0 10px;
        }

        @include media-breakpoint-up(md) {
            margin: 0 40px;

            .stat-select {
                max-width: 255px;
            }
        }

        .screen-overview-wrapper {
            display: flex;
            flex-direction: row;
            margin-right: auto;
            height: 100% !important;
            min-height: 85px !important;
            max-height: 100% !important;

            @include media-breakpoint-down(sm) {
                padding: 10px 16px;
            }

            @include media-breakpoint-up(sm) {
                padding: 16px;
            }

            .screen-overview {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                width: 100%;

                .screen-name {
                    h2 {
                        @include media-breakpoint-down(sm) {
                            width: calc(100% - 60px);
                        }
                    }
                }
            }

            .asset-log-button {
                margin-left: 20px;
            }
        }
    }
}

.bottom-controls {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 1000;

    @include media-breakpoint-down(md) {
        padding: 0 10px 10px 10px;
    }

    @include media-breakpoint-up(md) {
        padding: 0 40px 20px 40px;
    }

    .stat-select {
        max-width: 200px;
    }

    .tile-view-zoom {
        @include media-breakpoint-up(sm) {
            max-width: 180px;
            margin-left: auto;
        }

        @include media-breakpoint-down(sm) {
            max-width: 90px;
            margin-left: 10px;
        }
    }
}

.mobile-settings-toggle {
    position: absolute;
    top: 5px;
    right: 15px;
    z-index: 1001;
}

.mobile-controls-drawer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 10px 30px 10px !important;

    .drawer-buttons {
        display: inline-flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 10px;

        .form-inline,
        .form-group {
            display: inline-flex;
            margin-bottom: 0;
        }
    }

    .asset-log-group {
        flex-shrink: 0;
        max-width: 320px;
    }
}

.tile-view {
    display: flex;
    align-items: center;
    height: calc(100vh - 71px); // Fallback
    height: calc(var(--vh, 1vh) * 100 - 71px);
    background-color: $page-dark;
    @include media-breakpoint-down(sm) {
        padding: 5px;
    }
    @include media-breakpoint-up(sm) {
        padding: 10px;
    }

    .centre-zoom-wrapper {
        display: flex;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        padding-top: 120px;

        @include media-breakpoint-down(sm) {
            padding-bottom: 80px;
        }

        .screen-wrapper {
            display: flex;
            width: 100%;
            cursor: grab;

            &:active {
                cursor: grabbing;
            }

            .screen-grid {
                display: grid;

                .section-grid {
                    display: grid;
                }
            }

            .logo-box-grid {
                margin-top: 20px;
                margin-bottom: 20px;
            }
        }
    }
}

.section-tile {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    text-align: center;
    word-break: break-word;
    white-space: normal;
    background-color: $missing-grey;
    overflow: hidden;
    border-radius: 0 !important;

    h6 {
        margin-bottom: 0;
    }
}

.proof-stat {
    width: 100%;
    height: 100%;
}

.screen-configuration-loading-or-error {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 71px); // Fallback
    height: calc(var(--vh, 1vh) * 100 - 71px);
    padding: 15px;
    background-color: $page-dark;
    color: $text-title;
    font-family: $font-family-light;
    font-size: 20px;
    text-align: center;
}

.logo-box-dialog {
    z-index: 1040;

    .logo-box-dialog-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px;

        .custom-select-wrapper {
            width: 170px;
        }
    }
}

.update-logo-box {
    position: fixed;
    bottom: 40px;
    left: 40px;
    z-index: 1000;

    img {
        height: 18px;
        margin-right: 7px;
    }
}

.error-status-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background-color: $missing-grey;
    border-radius: 4px;
}

.screen-lost-connection-toaster {
    width: 100%;
    padding-bottom: 0;

    @include media-breakpoint-down(sm) {
        padding: 0;
    }

    .bp3-toast {
        display: inline-flex;
        align-items: center;
        width: 100%;
        height: 26px;
        max-width: 100% !important;
        margin-top: 0;
        padding: 0 5px;

        .bp3-icon {
            margin: 0 8px 0 0;
        }

        .bp3-toast-message {
            padding: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .bp3-button-group {
            // Disable dismiss button for screen lost connection banner
            display: none;
        }
    }
}
