// Fonts
@font-face {
    font-family: 'Lato-Medium';
    src: url('../fonts/Lato/Lato-Medium.woff2') format('woff2');
}

@font-face {
    font-family: 'Lato-Semibold';
    src: url('../fonts/Lato/Lato-Semibold.woff2') format('woff2');
}

@font-face {
    font-family: 'Lato-Light';
    src: url('../fonts/Lato/Lato-Light.woff2') format('woff2');
}

@font-face {
    font-family: 'Lato-Black';
    src: url('../fonts/Lato/Lato-Black.woff2') format('woff2');
}

@font-face {
    font-family: 'Lato-Heavy';
    src: url('../fonts/Lato/Lato-Heavy.woff2') format('woff2');
}

$font-family-sans-serif: 'Lato-Medium', sans-serif;
$font-family-semibold: 'Lato-Semibold', sans-serif;
$font-family-light: 'Lato-Light', sans-serif;
$font-family-black: 'Lato-Black', sans-serif;
$font-family-heavy: 'Lato-Heavy', sans-serif;
$font-family-serif: Georgia, 'Times New Roman', Times, serif;
$font-family-monospace: Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
$font-family-base: $font-family-sans-serif;

// Colors
$theme-colors: (
    'primary': #165a8b,
    'danger': #bc1b1b,
);
$brand-primary: #165a8b;
$brand-secondary: #165a8b;
$brand-success: #53902c;
$brand-warning: #bc501b;
$brand-error: #bc1b1b;
$brand-danger: #bc1b1b;

$page-dark: #1b1d21;
$page-grey: #383c44;
$header-dark: #141518;
$filter-header: #ffffff;
$tooltip-background: #ffffff;
$dialog-background: #ffffff;
$card-background: #181a1e;
$card-header-background: #24272c;
$error-banner: #db3737;

$table-group-background: #000000;
$table-hover: #24272c;
$table-selected: #24272c;
$table-error: #8b1616;
$table-error-dark: #4b0202;
$table-subheader-background: #383c44;
$table-stopped: #5a0f0f;
$table-stopped-selected: #780a0a;
$table-background-odd: #202328;

$scrollbar-thumb: #ffffff;

$text-nav: #888888;
$text-nav-active: #ffffff;
$text-title: #ffffff;
$text-title-alt: #888888;
$text-body-alt: #888888;
$text-button: #ffffff;
$text-data: #ffffff;
$text-input: #ffffff;
$text-input-alt: #888888;
$text-input-disabled: #888888;
$text-primary: #888888;
$text-black: #000000;
$text-hover: #ffffff;
$text-active: #ffffff;
$text-badge: #333333;
$text-badge-alt: #ffffff;
$text-error-banner: #ffffff;
$text-select: #ffffff;
$text-select-alt: #141518;
$text-select-active: #165a8b;
$text-dropzone: #878787;
$text-console: #ffffff;

$input-background: #1a1b1f;
$input-background-alt: #f6f8f8;
$input-background-ghost: #141518;
$input-border: #333333;
$input-border-alt: #ced4da;
$badge-background: #ffffff;
$badge-background-alt: #333333;
$nav-dropdown-background: #e6e6e6;
$select-hover: #e6e6e6;
$outline-grey: #333333;
$outline-light-grey: #888888;
$outline-light: #ced4da;
$outline-white: #ffffff;
$outline-black: #000000;
$outline-primary: #165a8b;

$button-primary: #165a8b;
$button-primary-hover: #1f6ea7;
$button-primary-disabled: #1b1d21;
$button-primary-text: #ffffff;
$button-primary-text-alt: #888888;
$button-secondary: #333333;
$button-secondary-hover: #4d4d4d;
$button-secondary-text: #ffffff;
$button-secondary-border: #165a8b;
$button-dark: #000000;
$button-dark-alt: #333333;
$button-dark-hover: #302f2f;
$button-dark-alt-hover: #383c44;
$button-light: #ffffff;
$button-light-hover: #cccccc;
$button-checkbox-disabled: #6c757d;

$overlay-background: rgba(20, 21, 24, 0.5);
$overlay-background-light: rgba(177, 179, 183, 0.5);
$overlay-info-background: #ffffff;
$overlay-text: #141518;
$overlay-background-blur: blur(1px);

$cutout-box-shadow: -6px -6px 8px #101113, 0 0;

$custom-cyan: #17a2b8;
$custom-orange: #fd7e14;
$custom-yellow: #ffc107;
$custom-blue: #007bff;
$custom-purple: #6f42c1;

$charcoal-grey: #2c3135;
$greyish: #aaaaaa;
$pale-grey: #f6f8f8;
$medium-grey: #e6e6e6;
$warm-grey: #9f9f9f;
$missing-grey: #484848;

$icon-light: #fafafa;

// Default variables
$reports-bottom-controls: false;
$tile-view-bottom-controls: false;
