@import './_variables.scss';
@import './_mixins.scss';

.refine-live-results {
    background-color: $header-dark;
    border-bottom: 1px solid $outline-grey;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 10px 20px;
    z-index: 2;

    @include media-breakpoint-up(md) {
        position: sticky;
        top: 0px;
    }

    .custom-select-wrapper {
        width: 170px;
    }

    .checkbox-form-group {
        padding: 10px;
    }

    .live-zoom-slider-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: auto;
        color: $text-primary;
    }

    .back-to-top-button {
        @include media-breakpoint-up(md) {
            top: 96px;
        }
    }
}

.live-grid {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 50px; // Allow space for help widget and offline banner

    .screen-card {
        flex-shrink: 0;
        margin-top: 20px;
        margin-left: 15px;
        margin-right: 15px;
        margin-bottom: 20px;
        color: $text-title;
        font-size: 15px;

        @include media-breakpoint-up(md) {
            width: 322px;
        }

        @include media-breakpoint-down(sm) {
            width: 100%;
        }

        .screen-card-name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .screen-card-image-container {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            height: calc(100% - 80px);
            max-height: 410px;
            margin: 10px 0;
            padding: 15px;
            background-color: $header-dark;
            border: 1px solid $outline-grey;
            border-radius: 5px;

            &:hover {
                cursor: pointer;
            }

            .player-toggle {
                position: absolute;
                top: 5px;
                left: 5px;
                z-index: 1;
            }

            .screen-card-image {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                max-width: 100%;
                max-height: 100%;

                // Styling for crossfade container
                .live-crossfade-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    padding: 15px;

                    img {
                        flex-grow: 1;
                        flex-shrink: 0;
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }
        }

        .screen-card-data {
            width: 100%;
            position: relative;
            display: inline-flex;
            justify-content: space-between;
            align-items: center;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            height: 17px;
            font-size: 12px;

            img {
                margin-right: 8px;
            }

            .screen-card-resolution {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .screen-card-player-name {
                display: inline-flex;
                align-items: center;

                .bp3-popover-wrapper {
                    margin-right: 8px;

                    .bp3-popover-wrapper .sync-state {
                        height: 10px;
                    }
                }

                img {
                    margin-right: 0;
                }
            }

            .screen-card-liveimage-info {
                display: flex;
                align-items: center;
                white-space: nowrap;
                max-width: 100%;

                font-size: 14px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                .screen-card-image-name {
                    margin-left: 12px;
                    color: $text-primary;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}

.screen-not-playing {
    h6 {
        padding: 20px 25px;
        border-radius: 5px;
        color: $overlay-text;
        background-color: $overlay-info-background;
    }
}

.screen-summary {
    .custom-summary-modal-body {
        margin-top: 50px;
    }
}
