@import '../_variables.scss';
@import '../_mixins.scss';

.custom-dialog {
    background-color: $dialog-background !important;
    padding-bottom: 0 !important;
    user-select: none;

    .bp3-dialog-header {
        box-shadow: none;
    }

    .dialog-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px;
    }

    .dialog-actions {
        width: 100%;
        display: flex;
        flex-grow: 1;
        align-items: flex-end;
        flex-direction: row-reverse;
        margin-top: 20px;
    }

    .dialog-actions-space-between {
        flex-direction: row;
        justify-content: space-between;
    }

    .dialog-actions-center {
        flex-direction: row;
        justify-content: center;
    }
}
