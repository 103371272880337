@import './_variables.scss';
@import './_mixins.scss';

.application-navbar {
    display: block;
    position: relative;
    background-color: $header-dark;
    border-bottom: 1px solid $outline-grey;
}

.navbar {
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    min-height: 70px;
    overflow: visible;

    .navbar-brand {
        margin-right: 14px;

        @include media-breakpoint-up(sm) {
            img {
                height: 35px;
            }
        }

        @include media-breakpoint-down(sm) {
            margin-left: auto;
            margin-right: auto;
            // padding-right: 52px;

            img {
                height: 28px;
            }
        }

        &:hover {
            cursor: pointer;
        }
    }

    .navbar-nav {
        align-items: center;
    }

    .nav-item {
        margin-left: 18px;

        @include media-breakpoint-down(sm) {
            margin-left: 9px;
            margin-right: 9px;
        }

        &:hover {
            h3 {
                color: $text-nav-active;
            }
        }
    }

    .navbar-brand-links {
        flex: 1;
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        white-space: nowrap;

        @include media-breakpoint-up(md) {
            flex: 1;
        }
    }

    .navbar-toggle {
        padding-left: 0;

        @include media-breakpoint-up(md) {
            display: none;
        }
    }

    .navbar-links {
        @include media-breakpoint-down(sm) {
            border-bottom: 1px solid $outline-white;
        }
    }

    .navbar-user {
        flex: 1;
        justify-content: flex-end;

        @include media-breakpoint-down(sm) {
            display: flex;
            flex-direction: row;
            margin-bottom: 10px;
        }
    }
}

.navbar-org-selection {
    flex: 1;
    overflow: hidden;
    max-width: 100%;
    background-color: $header-dark;
    // z-index: 22; // Interferes with scrolling and tooltips
    padding: 10px 0;
    border-radius: 5px;

    .navbar-org-dropdown-toggle {
        justify-content: center;
        padding-right: 2rem;
        padding-left: 2rem;
        background-color: transparent !important;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;

        &:hover {
            cursor: pointer;
        }

        &.active h2 {
            color: $text-nav-active;
        }

        h2 {
            margin-bottom: 0;
            color: $text-nav;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .navbar-org-dropdown-title {
            overflow: hidden;

            h2 {
                @include media-breakpoint-up(md) {
                    font-size: 22px;
                }
            }
        }
    }
}

.navbar-org-select-overlay {
    display: flex;
    justify-content: center;
    width: 100%;
    z-index: 21;
}

.navbar-org-select-menu {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: auto;
    width: auto;
    min-width: 300px;
    max-width: 100vw;
    max-width: 1000px;
    margin: 60px auto 10px auto;
    padding: 10px;
    border-radius: 5px;
    background-color: $page-grey;

    .custom-search {
        width: 100%;
        max-width: 500px;
    }

    .navbar-org-grid {
        display: grid;
        grid-auto-flow: column;
        gap: 10px;

        @include media-breakpoint-down(md) {
            grid-auto-flow: unset;
            grid-template-columns: auto;
        }

        .navbar-org-grid-item {
            flex: 1;
            padding: 10px 20px;
            cursor: pointer;
            background-color: $card-background;
            border-radius: 5px;

            .org-title-details {
                width: 100%;
                display: inline-flex;
                align-items: center;
                white-space: nowrap;

                h2 {
                    margin-right: 20px;
                }

                .overview-tag-container {
                    justify-content: flex-end;
                    margin-bottom: 5px;
                }

                @include media-breakpoint-down(md) {
                    flex-wrap: wrap;

                    .overview-tag-container {
                        justify-content: flex-start;
                    }
                }
            }

            .screen-tag {
                margin-right: 5px;
                margin-bottom: 5px;
                color: $button-secondary-text;
                background-color: $button-secondary;

                &:hover {
                    background-color: $button-secondary-hover;
                }
            }

            &:hover {
                background-color: $table-hover;
            }
        }
    }

    .navbar-org-select-no-results {
        flex: 1;
        padding: 10px 20px;
        text-align: center;
        color: $text-nav;
        font-size: 18px;
    }
}

.dropdown-toggle {
    color: $text-nav-active;

    &:hover {
        color: $text-nav-active;
        cursor: pointer;
    }
}

.org-logo {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 90px;
    height: 40px;
    margin-right: 20px;

    img {
        max-width: 90px;
        max-height: 40px;
        object-fit: contain;
    }
}

.mobile-nav-drawer {
    z-index: 1030 !important;

    .mobile-nav-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        border-bottom: 1px solid $outline-grey;

        .navbar-brand {
            margin-left: auto;
            margin-right: auto;

            img {
                height: 28px;
            }
        }
    }

    .mobile-nav-navlinks {
        flex-direction: column;
        padding: 30px;
        border-bottom: 1px solid $outline-grey;
    }

    .mobile-nav-user-navlinks {
        flex-direction: column;
        padding: 30px;
    }

    @include not-last-child('.nav-item') {
        .nav-link {
            margin-bottom: 10px;
        }
    }
}

.nav-item {
    .nav-link {
        h3 {
            margin-bottom: 0;
            color: $text-nav;
        }
    }

    .active {
        h3 {
            color: $text-nav-active;
        }
    }

    .nav-sublink {
        margin-left: 20px;
    }
}

.welcome-popover-backdrop {
    background-color: $overlay-background;
    // Firefox compatibility is limited to users who have explicity enabled this feature, https://developer.mozilla.org/en-US/docs/Web/CSS/backdrop-filter
    backdrop-filter: $overlay-background-blur;
}

.welcome-popover {
    max-width: calc(100% - 10px);

    .welcome-popover-content {
        display: flex;
        flex-direction: column;
        padding: 10px;
        color: $text-black;

        @include media-breakpoint-up(sm) {
            max-width: 400px;
        }

        h3 {
            color: unset;
        }

        .welcome-buttons {
            margin-left: auto;
        }
    }
}
