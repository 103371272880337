@import '../_variables.scss';
@import '../_mixins.scss';

.zoom-slider {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 174px;
    padding: 10px;
    margin: 0;

    .custom-button-link {
        margin: 0;
        padding: 0;
    }
}
