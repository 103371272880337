@import './_variables.scss';
@import './_mixins.scss';

.player-loading {
    h6 {
        padding: 25px;
        border-radius: 5px;
        color: $overlay-text;
        background-color: $overlay-info-background;
    }
}

.player {
    height: 100%;
    padding: 20px;

    .player-header {
        display: inline-flex;
        align-items: center;
        width: 100%;

        .player-refresh-button {
            margin-left: auto;
        }
    }

    .player-actions {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 30px;

        .custom-search {
            flex-grow: 0;
            flex-shrink: 0;
            margin-right: 20px;
        }

        .custom-select-wrapper {
            // Prevent accessibility text showing in prod build
            & > span {
                display: none;
            }
        }
    }

    .player-settings {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        min-height: 48px;
        margin-top: 10px;

        .settings-group {
            display: inline-flex;
            align-items: center;
            margin-right: 10px;

            h4,
            h5 {
                margin-bottom: 0;
                margin-right: 10px;
                white-space: nowrap;
            }

            button {
                margin-right: 10px;
            }

            .custom-text-input {
                width: 40px;
                margin-right: 0;
            }

            .custom-select-wrapper {
                display: inline-flex;
                width: 126px;
                height: 38px;

                // Prevent accessibility text showing in prod build
                & > span {
                    display: none;
                }

                .custom-select__control {
                    min-width: 100%;
                }
            }

            .custom-text-input {
                max-width: 80px;
            }

            .custom-button-edit {
                margin-left: 10px;
            }
        }
    }

    .player-table {
        height: calc(100vh - 360px); // Fallback
        height: calc(var(--vh, 1vh) * 100 - 360px);

        .bvt-table {
            .ReactVirtualized__Table__headerRow {
                padding-left: 15px; // Draggable handle offset
                padding-right: 34px !important; // Clear icon offset
            }

            .ReactVirtualized__Table__Grid {
                user-select: none; // Prevents triggering onDrag for images
            }
        }
    }

    .player-no-results {
        display: flex;
        align-items: center;
        justify-content: center;
        height: calc(100% - 280px);
        padding: 20px;
        text-align: center;
    }
}

// NOTE: react-virtualized row can be rendered outside of table wrapper when sorting
.player-row {
    width: calc(100vw - 60px) !important; // Maintain width when dragging
    height: 60px !important;
    margin: 9px 10px 1px 1px;
    padding-right: 15px !important;
    background-color: $table-group-background;
    border: none !important;
    border-radius: 5px;
    overflow: visible !important;

    &.pending-delete div:not(:last-child) {
        opacity: 0.5;
        pointer-events: none;
    }

    &.pending-upload {
        box-shadow: 0px 0px 0px 1px $outline-white;
    }

    .player-table-img {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 0;
        height: 40px;

        .warning-img {
            position: absolute;
            width: 20px;
            margin-top: -8px;
            margin-left: -8px;
        }

        .media-img {
            max-width: 100px;
            height: auto;
            max-height: 40px;
        }
    }

    .player-table-dwell-time {
        display: inline-flex;
        overflow: visible !important;

        .custom-text-input {
            width: 60px;
            margin-right: 0;

            &:not(:focus) {
                background-color: transparent;
            }
        }

        .clear-input {
            height: 14px;
            margin-top: -3px;
            margin-left: -10px;

            &:hover {
                filter: brightness(110%);
                cursor: pointer;
            }
        }
    }

    .player-table-schedule-icon {
        cursor: pointer;
    }

    .player-table-warning {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .player-table-duplicate {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            max-width: 25px;

            &:hover {
                cursor: pointer;
            }
        }
    }

    .player-table-action {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        overflow: visible !important;

        img {
            max-width: 25px;

            &:hover {
                cursor: pointer;
            }
        }

        .cancel-upload-icon {
            position: absolute;
            top: -10px;
            right: -10px;
        }
    }
}

.player-img-popover-content {
    background-color: #000000;
    border: 2px solid $outline-grey;

    .zoom-img {
        img {
            max-width: 250px;
            max-height: 250px;
        }
    }

    .img-details {
        padding: 2px;
    }
}

.player-footer-actions {
    position: fixed;
    bottom: 0;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100px;
    padding: 20px;
    background-color: rgba(20, 21, 24, 0.96);
    border-top: 1px solid $outline-white;
    color: $text-button;

    .save-playlist {
        display: inline-flex;

        .save-media-button {
            margin-left: 20px;
        }
    }
}

.player-schedule-dialog {
    width: 520px !important;
    flex-shrink: 0;

    .player-schedule-dialog-content {
        display: flex;
        flex-direction: column;
        height: 620px;
        padding: 11px;

        h4 {
            margin-top: 10px;
            color: $text-black;
        }

        .bp3-popover-wrapper {
            display: flex;
            justify-content: center;
        }

        .schedule-datepicker-subheaders {
            display: inline-flex;

            div:first-child {
                padding-left: 6px;
            }

            div:last-child {
                padding-left: 13px;
            }
        }

        .schedule-datepicker {
            .custom-datepicker-input {
                display: flex;
                justify-content: center;
            }

            .custom-datepicker-input > div {
                max-width: 490px;
            }

            .bp3-datepicker .DayPicker {
                margin: 12px;
            }

            .DayPicker-Day--selected-range-start {
                background-color: $brand-success;

                &:hover {
                    background-color: $brand-success;
                }
            }

            .DayPicker-Day--selected-range-end {
                background-color: $brand-danger;

                &:hover {
                    background-color: $brand-danger;
                }
            }
        }
    }
}
