@import './_variables.scss';
@import './_mixins.scss';

.reports-container {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 71px); // Fallback
    min-height: calc(var(--vh, 1vh) * 100 - 71px);

    .form-inline .form-group {
        flex-shrink: 1;
    }
}

.reports-non-ideal-state {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    height: 100%;
    padding: 15px;
    background-color: $page-dark;
    text-align: center;

    h2 {
        max-width: 465px;
    }
}

.reports-filter-group {
    margin: 0 10px 10px 10px !important;
    padding: 10px;
    border-radius: 7px;
    background-color: $page-dark;

    @include media-breakpoint-up(md) {
        flex-wrap: nowrap !important;
        width: auto !important;
        margin-left: 0;
        margin-right: 20px;

        .custom-select-wrapper {
            margin-left: 10px;

            .custom-select__menu {
                position: absolute;
                right: 0;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        width: 100%;
        margin-left: 15px;
        margin-right: 15px;

        .custom-label {
            margin-bottom: 15px;
        }
    }

    .bp3-popover-wrapper {
        @include media-breakpoint-down(md) {
            flex-grow: 1;

            .custom-datepicker-input .datepicker-tags {
                width: 100%;

                .datepicker-tag {
                    min-width: 130px !important;
                }
            }
        }
    }
}

.reports-filter-group-inverted {
    background-color: $header-dark;
}

.reports-sticky-banner {
    display: flex;
    flex-direction: column;
    background-color: $header-dark;
    z-index: 18;

    @include media-breakpoint-up(md) {
        position: sticky;
        top: 0px;
    }

    .content-filters {
        padding: 20px 0 10px 0;

        @include media-breakpoint-up(md) {
            display: inline-flex;
            justify-content: center;
            width: 100%;
            padding-left: 50px;
            padding-right: 50px;
            border-bottom: 1px solid $outline-grey;
            z-index: 19;
        }

        .content-filters-buttons {
            display: inline-flex;
            justify-content: center;
            margin: 0 10px 10px 10px;

            @include media-breakpoint-down(sm) {
                width: 100%;
            }

            .content-filters-locked {
                width: 102px;

                img {
                    height: 18px;
                }
            }

            .content-filters-edit span {
                text-transform: uppercase;
            }
        }
    }

    .content-filters-scrolled {
        @include media-breakpoint-up(md) {
            padding-top: 20px;
        }
    }

    .refine-results-badge-container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        padding-top: 10px;
        padding-bottom: 10px;
        background-color: $header-dark;
        border-bottom: 1px solid $outline-grey;
        transition: transform 0.6s cubic-bezier(0, 0.25, 0, 1);

        &.show {
            transform: translateY(0);
            visibility: visible;
        }

        &.hide {
            transform: translateY(-51px);
            margin-bottom: -51px;
            visibility: hidden;
        }

        @include media-breakpoint-up(md) {
            position: sticky;
            z-index: 18;
            top: 95px;
        }

        .refine-results-badge {
            height: 30px;
            padding: 10px !important;
            margin-right: 9px;
            border-radius: 15px !important;
            background-color: $page-dark !important;
            color: $text-data;
            font-size: 12px !important;
            font-weight: 500;

            img {
                margin-top: -4px;
                padding-right: 8px;
            }
        }
    }
}

.refine-results {
    padding-top: 20px;
    background-color: $page-dark;

    @include media-breakpoint-up(md) {
        display: inline-flex;
        justify-content: center;
        padding-left: 30px;
        padding-right: 30px;
    }

    .reports-search {
        flex-shrink: 1;
        margin-left: 10px;
        margin-right: 10px;
    }

    .search-filter-button {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        margin: 0 10px 10px 10px;

        @include media-breakpoint-down(sm) {
            width: 100%;
        }

        .custom-search {
            margin-left: 0;
            margin-bottom: 0;
        }
    }
}

.results-table {
    flex-grow: 1;
    height: 100%;
    padding-top: 20px;
    padding-bottom: 50px; // Allow space for help widget and offline banner
    background-color: $page-dark;

    table {
        tr {
            display: flex;
            align-items: center;

            td {
                border: none;
                color: $text-data;
                font-size: 12px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        .results-table-icons-table {
            img {
                margin-right: 7px;
            }
        }

        .results-table-row {
            border-top: 1px solid $outline-grey;

            .results-table-row-inner {
                height: 80px;

                .select-image-wrapper {
                    display: flex;
                    align-items: center;
                    width: 48px;
                    height: 100%;
                    min-height: 68px;
                }

                .results-table-image-cell {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    // align-items: center;
                    width: 128px;
                    height: 100%;

                    .image-unnamed-tooltip {
                        z-index: 1;
                        .bp3-popover-wrapper,
                        .bp3-popover-target {
                            height: 0;
                        }
                    }

                    .display-image-error {
                        position: relative;
                        margin-top: -5px;
                        margin-left: -5px;
                    }

                    .results-table-image {
                        flex-grow: 1;
                        flex-shrink: 0;
                        width: 100%;
                        height: 100%;
                        max-width: 110px;
                        max-height: 50px;
                        object-fit: contain;

                        @include media-breakpoint-down(sm) {
                            max-width: 86px;
                            max-height: 40px;
                        }

                        &:hover {
                            filter: brightness(110%);
                            cursor: pointer;
                        }
                    }

                    .display-image-missing-container {
                        display: inline-flex;
                        justify-content: center;
                        width: 100%;
                        background-color: $warm-grey;

                        img:hover {
                            filter: unset;
                        }
                    }
                }

                .results-table-open-report {
                    display: flex;
                    align-items: center;
                    width: 48px;
                    height: 100%;
                    min-height: 68px;
                }
            }
        }

        .results-table-row-selectable {
            &:hover {
                background-color: $table-hover;
                cursor: pointer;
            }
        }

        .results-table-row-selected {
            background-color: $table-selected;
        }

        .results-table-row-error {
            background-color: rgba(0, 0, 0, 0.5);
            border: 2px solid $table-error;
        }

        .results-table-title {
            color: $text-title;
            font-size: 15px;
            padding-bottom: 0;
            white-space: nowrap;

            .copyable-title {
                display: inline-flex;
                align-items: center;

                img {
                    margin-right: 0;
                }

                .bp3-popover-wrapper {
                    margin-right: 10px;
                }
            }
        }

        .results-table-filename-row {
            td {
                padding-top: 6px;
                padding-bottom: 10px;
            }

            .results-table-title {
                font-size: 12px;
            }
        }
    }
}

.expand-image-dialog {
    width: 800px;
    max-width: 100vw;

    .image-expanded-dialog-content {
        min-height: 200px;
        max-height: calc(100vh - 200px); // Fallback
        max-height: calc(var(--vh, 1vh) * 100 - 200px);

        h3 {
            max-width: 100%;
            margin-top: 18px;
            margin-bottom: 12px;
            color: $header-dark;
            font-size: 15px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .expanded-img {
            width: 100%;
            height: 100%;
            max-width: 100%;
            max-height: calc(100% - 80px);
            object-fit: contain;
            flex-grow: 1;
        }

        .expanded-img-error {
            height: 100%;
        }

        .expanded-image-badge-wrapper {
            display: inline-flex;

            .expanded-image-badge {
                height: 30px;
                padding: 9px !important;
                margin-right: 9px;
                border-radius: 15px !important;
                background-color: $header-dark !important;
                color: $text-data;
                font-size: 12px !important;

                img {
                    margin-top: -4px;
                    padding-right: 8px;
                }
            }
        }
    }
}

.selected-images-container {
    position: sticky;
    z-index: 20;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 86px;
    background-color: $page-dark;
    border-top: 1px solid $outline-white;
    color: $text-title;
    font-size: 14px;

    .selected-images-controls {
        display: flex;
        align-items: center;
        margin-right: 30px;

        @include media-breakpoint-down(sm) {
            justify-content: space-between;
            width: 100%;
            margin-left: 10px;
            margin-right: 10px;
        }

        .selected-images-count {
            display: flex;
            align-items: center;
            flex-shrink: 0;
            height: 55px;
            padding-left: 5px;
            padding-right: 15px;
            border-right: 1px solid $outline-grey;

            @include media-breakpoint-down(sm) {
                &:hover {
                    cursor: pointer;
                }
            }

            img {
                height: 9px;
                margin-left: 12px;
            }
        }

        .selected-images-clear-all {
            height: 55px;
            margin-left: 10px;

            span {
                border-bottom: 1px solid $outline-white;
                font-family: $font-family-semibold;
                font-size: 14px;
                color: $button-primary-text;
            }
        }

        .selected-images-report-submit {
            width: 114px;
            height: 55px;
            margin-left: 20px;
            font-size: 14px;
            text-transform: uppercase;

            .btn {
                border: none;
                color: $text-primary;
            }
        }
    }
}

.selected-images-carousel {
    height: 100%;
    display: flex;
    justify-content: flex-start;

    @include media-breakpoint-up(md) {
        align-items: center;
        width: calc(100vw - 475px);
        padding: 0 30px;
        overflow-x: auto;
    }

    @include media-breakpoint-down(sm) {
        align-content: flex-start;
        flex-wrap: wrap;
        position: fixed;
        top: 0;
        height: calc(100vh - 86px); // Fallback
        height: calc(var(--vh, 1vh) * 100 - 86px);
        width: 100vw;
        padding: 10px;
        overflow-y: auto;
        z-index: 19;
    }

    .selected-images-carousel-item {
        display: inline-flex;
        margin-right: 24px;

        @include media-breakpoint-down(sm) {
            margin-bottom: 24px;
        }

        .selected-images-clear {
            height: 20px;
            margin-top: -5px;
            margin-left: -15px;
            filter: drop-shadow(0 2px 1px rgba(0, 0, 0, 0.7));

            &:hover {
                filter: brightness(110%);
                cursor: pointer;
            }

            img {
                height: 20px;
            }
        }

        .selected-images-carousel-image {
            max-width: 130px;
            max-height: 60px;

            @include media-breakpoint-down(sm) {
                max-height: 130px;
            }

            &:hover {
                filter: brightness(110%);
                cursor: pointer;
            }
        }
    }
}

.mobile-selected-images-drawer {
    z-index: 19;
}

.image-summary-modal-scope-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 36px;

    .image-summary-modal-scope {
        display: flex;
        align-items: center;
        min-width: 290px;
        height: 55px;
        padding: 10px;
        background-color: $page-dark;
        border-radius: 7px;
        color: $text-title-alt;
        font-size: 14px;

        &:first-child {
            margin-right: 20px;
        }

        .image-summary-modal-scope-label {
            margin-right: 20px;
            color: $text-title;
        }
    }
}

.image-summary-export {
    display: flex;
    margin-bottom: 40px;

    .image-summary-export-input {
        width: 200px !important;
        height: 35px;
        margin-right: 10px;
        padding-top: 7px;
        padding-left: 10px;
        background-color: $input-background;
        border-radius: 5px !important;
        border: 1px solid $outline-grey;
        color: $text-input;
        font-size: 14px;
    }

    .custom-select-wrapper {
        width: 170px;
    }

    .image-summary-export-submit {
        width: 145px;
        height: 55px;
        margin-left: 20px;
        font-size: 14px;
        text-transform: uppercase;

        .btn {
            border: none;
            color: $text-primary;
        }
    }
}

.image-summary-totals {
    color: $text-data;
    font-size: 12px;

    @include not-last-child('span') {
        margin-right: 25px;
    }

    img {
        margin-right: 7px;
    }

    .image-summary-totals-title {
        padding-right: 5px;
        font-family: $font-family-black;
        color: $text-data;
        font-size: 14px;
        text-transform: uppercase;
    }
}

.report-summary-drawer {
    .bp3-drawer {
        background-color: $header-dark !important;
    }

    .custom-container {
        padding: 30px;
    }

    .modal-content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-grow: 1;
        margin-left: auto;
        margin-right: auto;
        background-color: transparent;
        border: none;

        .custom-summary-modal-body {
            padding: 20px 30px 30px 30px;

            .custom-summary-body-content {
                margin-top: 80px;
            }
        }
    }

    .custom-summary-modal-content {
        padding: 20px;
        background-color: $page-dark;
        border-radius: 7px;
    }

    .custom-summary-modal-image-row-container {
        @include not-last-child('.custom-summary-modal-content') {
            margin-bottom: 20px;
        }

        .custom-summary-image-container {
            display: flex;

            @include media-breakpoint-down(sm) {
                margin-top: 30px;
            }

            .error {
                position: relative;
                margin-top: -5px;
                margin-right: -15px;
                height: 20px;
            }

            .modal-image {
                max-height: 110px;
                max-width: 236px;
            }

            .missing-container {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 50px;
                width: 110px;
                background-color: $warm-grey;
            }

            .missing {
                height: 30px;
            }

            .modal-image-title {
                margin-top: 10px;
                padding-left: 25px;
                color: $text-title;
                font-size: 15px;
                max-width: 332px;
                overflow-wrap: break-word;
            }
        }
    }
}

.custom-summary-table {
    margin-top: 30px;

    table {
        td {
            border: none;
            color: $text-data;
            font-size: 12px;
            white-space: nowrap;
        }

        .custom-summary-table-row {
            padding-top: 14px;
            padding-bottom: 14px;
            border-top: 1px solid $outline-grey;

            img {
                margin-right: 7px;
            }
        }

        .table-title {
            color: $text-title;
            font-size: 15px;
            padding-bottom: 0;
            white-space: nowrap;
        }
    }
}

.report-summary-modal-close {
    position: absolute;
    top: 20px;
    right: 30px;
    height: 45px;

    &:hover {
        filter: brightness(110%);
        cursor: pointer;
    }
}

.export-report-dialog {
    h3 {
        color: $text-black;
    }
}
