@import './_variables.scss';
@import './_mixins.scss';

.diagnostics-container {
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 72px); // Fallback
    min-height: calc(var(--vh, 1vh) * 100 - 72px);
    background-color: $page-dark;

    .diagnostics-content {
        width: 100%;
    }

    @include media-breakpoint-down(sm) {
        width: 100vw;
        transition: transform 0.6s cubic-bezier(0, 0.52, 0, 1);

        &.show-mobile {
            margin-left: -100vw;
        }

        &.hide-mobile {
            margin-left: 0;
        }
    }

    .tab-content,
    .tab-content > .active {
        display: flex;
        flex-direction: column;
        height: 100%;
        flex-grow: 1;
    }
}

.diagnostics-selected-filter {
    margin: 0 20px 20px 20px;

    .row {
        flex-wrap: nowrap;
        margin-top: 10px;

        span {
            font-size: 15px;
            color: $text-title;
        }

        .bp3-tag {
            background-color: $button-primary;

            // margin-left: 30px
            font-size: 12px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            .bp3-tag-remove {
                margin-left: 3px;
                border-left: 1px solid $header-dark;
            }
        }
    }
}

.diagnostics-screens-table {
    height: 100%;

    .bvt-table {
        .ReactVirtualized__Table__headerRow {
            padding-left: 20px !important;
            padding-right: 20px !important;
        }

        .ReactVirtualized__Table__Grid {
            padding-bottom: 60px; // Allow space for help widget and offline banner
            user-select: none;
        }

        .ReactVirtualized__Table__row {
            padding-left: 20px !important;
            padding-right: 0 !important;

            &:hover {
                background-color: $table-hover;
                cursor: pointer;
            }

            .status-col {
                overflow: visible !important;
            }

            .screen-info-cell {
                margin-right: 0 !important;

                .diagnostics-overview-screen-heading {
                    height: 40px;

                    h6 {
                        margin-bottom: 0;
                    }

                    .screen-subheading {
                        display: inline-flex;
                        align-items: center;
                        margin-top: 5px;

                        h6 {
                            padding-top: 3px;
                        }

                        .screen-subheading-icon {
                            display: flex;
                            justify-content: center;
                            width: 14px;
                            margin-right: 6px;

                            img {
                                height: 14px;
                            }
                        }
                    }
                }
            }

            .screen-avior-cell {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .ReactVirtualized__Table__rowColumn {
                padding-top: 10px !important;
                padding-bottom: 10px !important;
            }
        }
    }
}

.status-cell {
    display: flex;
}

.screen-name {
    display: inline-flex;
    align-items: center;
    width: 100%;

    h2 {
        margin-bottom: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.screen-details {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    color: $text-data;
    font-size: 15px;

    .details {
        display: inline-flex;
        max-width: 100%;
        margin-top: 10px;
        margin-right: 20px;

        h4,
        h5 {
            margin-bottom: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        h4 {
            margin-right: 8px;
        }

        .details-icon {
            height: 15px;
            margin-right: 8px;
        }
    }

    .tooltip-details-target {
        align-items: center;
        cursor: pointer;
    }
}

.tooltip-details {
    flex-direction: column;
    align-items: flex-start;

    .details:first-child {
        margin-top: 0;
    }
}

.diagnostics-back-arrow {
    margin-right: 7px;
}

.screen-summary-container {
    display: flex;
    flex-shrink: 0;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 20px;

    .screen-summary-header {
        display: flex;
        margin-bottom: 16px;

        .screen-summary {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .screen-summary-actions {
        display: flex;
        margin-bottom: 16px;
        flex-grow: 1;

        @include media-breakpoint-up(md) {
            justify-content: flex-end;
        }
    }
}

.screen-tabs-container {
    display: inline-flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-wrap: wrap;

    .screen-tabs {
        flex-grow: 1;
        margin-bottom: 10px;
    }

    .custom-select-wrapper {
        max-width: 200px;
        margin-bottom: 10px;
    }
}

.screen-tabs-contextual-actions {
    display: flex;
    justify-content: flex-end;
    flex-grow: 0;
    flex-shrink: 0;
    margin-bottom: 10px;

    @include media-breakpoint-down(md) {
        align-self: center;
        margin-left: auto;
    }

    @include media-breakpoint-up(md) {
        min-width: 150px;
        margin-right: -20px;
        padding: 10px;
        border-top-left-radius: 5px;
        background: $page-dark;
        box-shadow: $cutout-box-shadow;
    }

    h5 {
        border-bottom: none !important;
    }

    .form-group {
        margin-right: 0;
        margin-bottom: 0;
    }

    .errors-table-collapse-all {
        margin-right: 8px;
        padding-left: 8px;
        padding-right: 8px;
        border-right: 1px solid $outline-grey;
        border-radius: 0;

        @include media-breakpoint-down(md) {
            margin-top: 0;
        }
    }

    .custom-button-link-black span {
        border: none;
    }

    .errors-table-compact-view {
        align-items: center;
        height: 28px;

        @include media-breakpoint-down(sm) {
            margin-bottom: 0;
        }

        .custom-form-group-inline {
            display: inline-flex;
            align-items: center;
        }
    }
}

.screen-errors {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-grow: 1;

    .diagnostics-errors-content {
        display: flex;
        flex-grow: 1;
        overflow: hidden;

        .diagnostics-error-group-container {
            display: flex;
            flex-direction: column;
            width: 100%;
            flex-shrink: 0;

            // Error group list
            .error-group-list {
                height: 100%;

                [class*='ReactVirtualized__'] {
                    &:focus {
                        outline: none !important;
                    }
                }

                .bvl-list {
                    padding-bottom: 50px; // Allow space for help widget and offline banner

                    .error-group-list-row-wrapper {
                        padding-bottom: 10px;

                        .error-group-list-row {
                            height: 100%;
                            background-color: $table-group-background;
                            border-radius: 7px;

                            .collapsable {
                                cursor: pointer;
                            }

                            .error-group-list-group {
                                display: inline-flex;
                                align-items: center;
                                width: 100%;
                                max-width: 100%;
                                padding: 10px;
                                overflow-x: auto;

                                .group-name-assign-cell {
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    width: 100%;
                                    margin-right: 10px;

                                    @include media-breakpoint-down(sm) {
                                        button span {
                                            border-bottom: none;
                                        }
                                    }

                                    h6 {
                                        margin-bottom: 0;
                                        white-space: nowrap;
                                        text-overflow: ellipsis;
                                        overflow: hidden;
                                    }
                                }
                            }

                            .custom-table-expander {
                                display: flex;
                                justify-content: center;
                                margin: 0 10px;

                                img {
                                    height: 9px;
                                }
                            }

                            // Error table
                            .error-table {
                                width: 100%;
                                height: calc(100% - 50px);
                                padding-left: 10px;
                                padding-right: 10px;
                                font-size: 14px;

                                .bvt-table {
                                    .ReactVirtualized__Table__headerRow {
                                        padding-right: 0 !important;
                                        background-color: $table-subheader-background;
                                    }

                                    .ReactVirtualized__Table__Grid {
                                        .ReactVirtualized__Table__row {
                                            padding-right: 0 !important;
                                            cursor: pointer;

                                            &:last-child {
                                                border-bottom: none;
                                            }
                                        }

                                        .assigned-cell-wrapper {
                                            height: 32px;
                                            padding: 0 !important;

                                            .assigned-cell {
                                                height: 100%;
                                                padding: 7px 10px;
                                                text-align: center;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.screen-error-history {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-grow: 1;

    .history-controls-collapse {
        z-index: 1;
    }

    .selected-search-params-tags {
        margin-bottom: 10px;

        .bp3-tag {
            background-color: $button-primary;
            margin-right: 10px;
        }
    }

    .custom-input-group {
        flex-grow: unset;

        @include media-breakpoint-up(sm) {
            flex-wrap: nowrap !important;
            margin-right: 10px;

            .custom-label {
                margin-right: 10px;
            }
        }

        @include media-breakpoint-down(xs) {
            .custom-label {
                margin-bottom: 15px;
            }
        }

        @include media-breakpoint-down(sm) {
            width: 100%;
            flex-wrap: wrap;
            height: unset;
            max-height: unset;

            .bp3-popover-wrapper {
                width: 100%;
                flex-grow: 1;

                .datepicker-tags {
                    width: 100%;

                    .datepicker-tag {
                        min-width: 120px !important;
                    }
                }
            }
        }

        .custom-select-wrapper {
            min-width: 180px;
        }
    }

    .scope-button {
        @include media-breakpoint-up(sm) {
            margin-bottom: 10px;
        }
    }

    .error-log-wrapper {
        display: flex;
        height: 100%;
        flex: 1 100%;
        width: 100%;

        .error-log-table {
            height: unset;
        }
    }
}

.diagnostics-non-ideal-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-grow: 1;
    padding: 15px;
    text-align: center;

    h2 {
        margin-bottom: 20px;
    }

    span {
        max-width: 574px;
    }
}

.diagnostics-no-selection {
    .overview-tag-container {
        display: flex;
        justify-content: center;
    }
}

.digest-overview-tag-container {
    display: inline-block;
    width: 100%;
    text-align: center;

    .overview-tag {
        display: inline-block;
        margin-bottom: 5px;
        margin-right: 5px;
        padding: 10px;
        border-radius: 5px;
        font-size: 16px;
        color: #ffffff;
        border: 1px solid $outline-light-grey;

        @include media-breakpoint-down(sm) {
            width: calc(50% - 5px);
        }
    }
}
