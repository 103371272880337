@import '../_variables.scss';
@import '../_mixins.scss';

.custom-panel-stack {
    .bp3-panel-stack-header {
        position: sticky;
        top: 0;
        left: 0;
        width: 100%;
        max-width: 500px;
        background-color: $overlay-info-background;

        .bp3-heading {
            display: none;
        }
    }
}

.custom-panel {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    height: 100%;
    align-items: center;
    padding: 20px 20px 0 20px;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    a {
        color: #000000;
    }

    .custom-panel-selected-org {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        width: 100%;
        padding-bottom: 5px;
        margin-bottom: 20px;
        border-bottom: 1px solid $outline-light;
        font-weight: bold;
    }

    .custom-panel-select-item,
    .custom-panel-create-new {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .custom-panel-footer-actions {
        display: flex;
        flex-grow: 1;
        align-items: flex-end;
        flex-direction: row-reverse;
        width: 100%;
        min-height: 75px;
        padding-bottom: 20px;
    }
}
