@import '../_variables.scss';
@import '../_mixins.scss';

.overview-tag-container {
    width: 100%;
    display: inline-flex;
    text-align: center;

    @include media-breakpoint-down(sm) {
        justify-content: flex-start;
    }

    .bp3-popover-wrapper {
        margin-right: 5px;
    }
}

.overview-tag {
    display: inline-block;
    min-width: 24px;
    margin-bottom: 0;
    padding: 5px;
    border-radius: 5px;
    font-size: 12px;
    color: #ffffff;
    border: 1px solid $outline-light-grey;
}
