@import '../_variables.scss';
@import '../_mixins.scss';

.dropzone-input-wrapper {
    width: 100%;
}

.custom-dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    border-width: 2px;
    border-radius: 2px;
    border-color: $outline-grey;
    border-style: dashed;
    color: $text-title;
    outline: none;
    transition: border 0.24s ease-in-out;

    &:focus {
        border-color: #2196f3;
    }

    &:hover {
        cursor: pointer;
    }
}

.custom-dropzone-fullscreen {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 20px;
    background-color: #333333;
    opacity: 0.9;
    z-index: 2001;
}
